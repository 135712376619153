﻿import "./check_box_form";
import "./flash";
import "./login";

$(function () {
  // search-navbar class switch to open the input
  $(".search-form-opener").on("click", function () {
    $(this).prev().toggleClass("opened");
  });
});

$(function () {
  $('[data-bs-toggle="tooltip"]').tooltip();
});
