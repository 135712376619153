import "../src/shared/wrap_ready.js";
import Cookie from "../src/shared/cookie";
import Header from "../src/hachette/components/header";

window.Hachette = {};

window.addEventListener("DOMContentLoaded", () => {
  window.Hachette.Cookie = new Cookie();

  window.Hachette.Header = new Header({ selector: ".nav-bar" });
  window.Hachette.Header.start();
});

// we moved Bootstrap here afer updating to BS5 because
// it wasn't loaded in the right order in application.js
import * as bootstrap from '../../../node_modules/bootstrap/dist/js/bootstrap.bundle.js';

window.bootstrap = bootstrap;

import "../../whitelabels/hachette/assets/javascripts/application.js";
